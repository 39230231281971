import actionEndpoint from './volunteering'

const schema = {
  endpoint: '/api/volunteer_timesheets',
  resource: {
    definition: '#VolunteerTimesheet',
    properties: {
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
        validate: ['required'],
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
        validate: ['required'],
      },
      pesel: {
        description: 'T_GENERAL_PESEL',
      },
      task: {
        type: 'resource',
        endpoint: `${actionEndpoint.endpoint_select}?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_MODULE_VOLUNTEERING_TIMESHEET_ACTION_SELECT',
        validate: ['required'],
      },
      dateFrom: {
        type: 'date',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'date',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_DATE_TO',
        validate: ['required'],
      },
      totalHours: {
        type: 'decimal',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_TOTAL_HOURS',
        validate: ['required', 'money'],
        asString: true,
      },
      description: {
        type: 'textarea',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_DESCRIPTION',
      },
      opinion: {
        type: 'textarea',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_OPINION',
        validate: ['required'],
      },
    },
  },
}

export default schema
